import React from "react";
import { useNavigate } from "react-router";
import LogoJuridico from '../../assets/images/juridico.jpg';
import LogoInmobiliaria from '../../assets/images/inmobiliaria.jpg';
import LogoArquitectura from '../../assets/images/arquitectura.jpg';
import { CardHomeContainer, AnclaContainer, Image, Title, Hr } from "./styled";

const logos = {
  juridico: LogoJuridico,
  inmobiliaria: LogoInmobiliaria,
  arquitectura: LogoArquitectura,
};

const CardHome = ({ name, image }) => {
  const navigate = useNavigate();

  const onClickLogo = () => {
    if (image === "arquitectura")
      navigate(`/projects/arquitectura`);
    else
      navigate(`/${image}`);
  };

  return (
    <CardHomeContainer>
      <AnclaContainer onClick={onClickLogo}>
        <Image src={logos[image]} alt="" />
        <Hr />
        <Title>{name}</Title>
      </AnclaContainer>
    </CardHomeContainer>
  )
};

export default CardHome;
