import React from 'react';
import ReactLoading from 'react-loading';
import './styled.css';

const Loading = ({ height='30%', width='30%', withLabel = true }) => (
  <div className='container-loading'>
    <ReactLoading
      type='spin'
      color='rgb(89,28,43)'
      height={height}
      width={width}
      className='loading-style'
    />
    {withLabel && <p className='p-loading'>Cargando ...</p>}
  </div>
);

export default Loading;
