import React from "react";
import { useNavigate } from "react-router";
import iconRural from '../../assets/images/iconRural.jpg';
import iconUrbano from '../../assets/images/iconUrbano.jpg';
import {
  ContainerRealState,
  Title,
  CardContainer,
  Image,
  Card,
  Hr,
} from "./styled";

const RealEstate = () => {
  const navigate = useNavigate();

  const onClickSection = (type_project) => {
    navigate(`/projects/${type_project}`);
  };

  return (
    <ContainerRealState>
      <CardContainer>
        <Card onClick={() => onClickSection("inmobiliario_rural")}>
          <Image src={iconRural} alt="" />
          <Hr />
          <Title>RURAL</Title>
        </Card>
      </CardContainer>
      <CardContainer >
        <Card onClick={() => onClickSection("inmobiliario_urbano")}>
          <Image src={iconUrbano} alt="" />
          <Hr />
          <Title>URBANO</Title>
        </Card>
      </CardContainer>
    </ContainerRealState>
  )
};

export default RealEstate;
