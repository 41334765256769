import styled from "styled-components";
import { FaWhatsapp, FaFacebook, FaInstagram, FaArrowUp } from 'react-icons/fa';

export const ContainerRedes = styled.div`
  position: fixed;
  right: 0;
  top: 30%;
  margin: 0.8% 0.8%;
  heigth: 200px;
  width: 50px;

  @media screen and (max-width: 768px) {
    width: 25px;
  }
`;

export const ContainerIcons = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerIcon = styled.a`
  cursor: pointer;
  margin-top: 10px;
`;

export const IconInstagram = styled(FaInstagram)`
  color: rgb(89,28,43);
  height: 32px;
  width: 32px;

  @media screen and (max-width: 768px) {
    height: 25px;
    width: 25px;
  }
`;

export const IconWhatsapp = styled(FaWhatsapp)`
  color: rgb(89,28,43);
  height: 32px;
  width: 32px;

  @media screen and (max-width: 768px) {
    height: 25px;
    width: 25px;
  }
`;

export const IconFacebook = styled(FaFacebook)`
  color: rgb(89,28,43);
  height: 32px;
  width: 32px;

  @media screen and (max-width: 768px) {
    height: 25px;
    width: 25px;
  }
`;

export const ArrowUp = styled(FaArrowUp)`
  margin-top: 35px;
  color: rgb(89,28,43);
  height: 32px;
  width: 32px;

  @media screen and (max-width: 768px) {
    height: 25px;
    width: 25px;
  }
`;
