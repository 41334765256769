import styled from "styled-components";

export const ContainerCarousel = styled.div`
  cursor: pointer;
  border: 0.1px solid #9B9B9B;
  border-radius: 10px;
  margin: 1%;
  width: 30%;

  @media screen and (max-width: 768px) {
    width: 94%;
  }
`;

export const Image = styled.img`
  width: 99.9%;
  height: 260px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const Hr = styled.hr`
  width: 74%;
  margin-left: 13%;
`;

export const Title = styled.h5`
  font-weight: bold;
  color: rgb(89,28,43);
  margin: 5%;
  text-overflow: ellipsis;
  width: 300px;
  overflow: hidden;
  white-space: pre-line;
`;

export const Description = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: bold;
  margin-top: 4%;
  padding-right: 5%;
  font-weight: 400;
  text-align: center;
`;

export const ContainerText = styled.div`
  cursor: pointer;
`;

export const City = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: rgb(89,28,43);
  margin-right: 13%;
  text-align: right;
`;
