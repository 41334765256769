import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import './styled.css';

const Pagination = ({ items , renderItem, per_page = 9 }) => {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const scrollTop = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  };

  const receivedData = () => {
    const data_items = items;
    const slice_data = data_items.slice(offset, offset + per_page);
    setData(slice_data);
    setPageCount(Math.ceil(data_items.length / per_page));
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setCurrentPage(selectedPage);
    const newOffset = selectedPage * per_page;
    setOffset(newOffset);
    scrollTop();
  };

  useEffect(() => {
    receivedData();
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    receivedData();
    // eslint-disable-next-line
  }, [items]);

  if (items && items.length > 0) {
    return (
      <div className="container-data">
        {renderItem(data)}
        <div className="container-pag">
          <div className="container-pagination">
            <ReactPaginate
              previousLabel={"Anterior"}
              nextLabel={"Siguiente"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(e) => handlePageClick(e)}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container-data">
        <div className="container-empty-page">
          <p className="empty-page">
            ACTUALMENTE NO HAY CONTENIDO PARA MOSTRAR EN ESTA SECCIÓN. POR FAVOR, REVISA DE NUEVO MÁS TARDE.
          </p>
        </div>
      </div>
    );
  }
};

export default Pagination;
