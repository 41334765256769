import React from "react";
import {
  ContainerRedes,
  IconWhatsapp,
  IconFacebook,
  IconInstagram,
  ArrowUp,
  ContainerIcons,
  ContainerIcon
} from "./styled";

const Redes = () => {

  const scrollTop = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  };

  return (
    <ContainerRedes>
      <ContainerIcons>
        <ContainerIcon target="_blank" href="https://api.whatsapp.com/send?phone=2664700620">
          <IconWhatsapp />
        </ContainerIcon>
        <ContainerIcon target="_blank" href="https://www.facebook.com/INMOBILIARIA2906?mibextid=LQQJ4d">
          <IconFacebook />
        </ContainerIcon>
        <ContainerIcon target="_blank" href="https://www.instagram.com/estudiojia.ma/">
          <IconInstagram />
        </ContainerIcon>
        <ContainerIcon onClick={scrollTop}>
          <ArrowUp />
        </ContainerIcon>
      </ContainerIcons>
    </ContainerRedes>
  )
};

export default Redes;
