import React from 'react';
import { useNavigate } from "react-router";
import Carousel from 'react-bootstrap/Carousel';
import { image_url } from "../../utils/utils";
import { Image, ContainerCarousel, Hr, Title, Description, ContainerText, City } from "./styled";
import imageNotFound from '../../assets/images/imageNotFound.png';

const ControlledCarousel = ({ project }) => {
  const navigate = useNavigate();

  const onClickProject = () => {
    navigate(`/project/${project.id}`);
  };

  return (
    <ContainerCarousel>
      {project.images.length > 0 ? (
        <Carousel slide={false}>
          {project.images?.map((image) => {
            return (
              <Carousel.Item key={image.id}>
                <Image
                  className="d-block w-100"
                  src={image_url(image.url)}
                  alt=""
                />
              </Carousel.Item>
            );
          })}
        </Carousel>
      ) : (
        <Carousel slide={false} style={{width: '95%'}}>
          <Carousel.Item>
            <Image
              className="d-block w-100"
              src={imageNotFound}
              alt=""
            />
          </Carousel.Item>
        </Carousel>
      )}
      <Hr />
      <ContainerText onClick={onClickProject}>
        <City>{`${project.city}, ${project.state}`}</City>
        <Title>{project.title}</Title>
        <Description>Leer más ...</Description>
      </ContainerText>
    </ContainerCarousel>
  );
}

export default ControlledCarousel;
