import React, { useEffect, useState } from 'react';
import ModalImage from "react-modal-image-responsive";
import { image_url } from "../../utils/utils";
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';
import { Title } from './styled';
import imageNotFound from '../../assets/images/imageNotFound.png';

const CarouselLarge = ({ project }) => {
  const [indexInt, setIndexInt] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const removeCurrentClass = (index) => {
    const item = document.getElementById(`item-image-${project.id}-${index}`);
    item?.classList.remove("active");
  };

  const addNewClass = (index) => {
    const item = document.getElementById(`item-image-${project.id}-${index}`);
    item?.classList.add("active");
  };

  const onClickNext = (index) => {
    if (index < project.images.length - 1) {
      removeCurrentClass(index);
      addNewClass(index+1);
      setIndexInt(index+1);
    } else {
      removeCurrentClass(index);
      addNewClass(0);
      setIndexInt(0);
    }
  };

  useEffect(() => {
    if (!modalOpen){
      const interval = setInterval(() => {
        onClickNext(indexInt);
      }, 3000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [indexInt]);

  const onClickPrev = (index) => {
    if (index > 0) {
      removeCurrentClass(index);
      addNewClass(index-1);
    }
  };

  useEffect(() => {
    addNewClass(0);
    // eslint-disable-next-line
  }, []);

  const ImageModal = ({ image }) => (
    <ModalImage
      className="images img-fluid rounded-start"
      hideZoom="false"
      hideDownload="true"
      small={image}
      large={image}
      alt=""
    />
  );

  return (
    <div className="arqPhotos col-12">
      <div className="card col-10">
        <div className="row g-0">
          <div className="col-md-7">
            <div id={project.id} className="carousel slide col-12" data-bs-ride="carousel">
              {project.images.length > 0 ? (
                <div className="carousel-inner col-12">
                  {project.images?.map((image, index) => {
                    return (
                      <div id={`item-image-${project.id}-${index}`} key={`${project.id}-${index}`} className="carousel-item col-12">
                        <ImageModal image={image_url(image.url)} onClick={()=>setModalOpen(!modalOpen)}/>
                        <button className="carousel-control-prev col-1" type="button" data-bs-target={`#${project.id}`} data-bs-slide="prev" onClick={()=>onClickPrev(index)}>
                          <span><FaCaretLeft size={30} style={{color: '#FFFFFF',background: 'rgb(89,28,43)', borderRadius: '10px'}}/></span>
                        </button>
                        <button className="carousel-control-next col-1" type="button" data-bs-target={`#${project.id}`} data-bs-slide="next" onClick={()=>onClickNext(index)}>
                          <span><FaCaretRight size={30} style={{color: '#FFFFFF',background: 'rgb(89,28,43)', borderRadius: '10px'}}/></span>
                        </button>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="carousel-inner col-12">
                  <div className="carousel-item active col-12">
                    <ImageModal image={imageNotFound} />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="informacion col-md-5">
            <div className="card-body">
              <Title>{project.title}</Title>
              <p className="contenidoInfo">{project.description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarouselLarge;
