import React from "react";
import {
  ContainerJuristic,
  ContainerInfo,
  Title,
  Description,
  Hr
} from "./styled";

const Juristic = () => (
  <ContainerJuristic>

    <ContainerInfo>
      <Title>Quienes somos?</Title>
      <Description>
        Estudio jurídico integral dedicado al asesoramiento en derecho civil, comercial, familia y penal en las tres Circunscripciones de la Provincia de San Luis y Juzgados Federales con asiento en la Provincia de San Luis.
      </Description>
      <Description>
        Contamos con abogados dedicados y especializados en las distintas ramas del derecho a los fines de poder cumplir con un asesoramiento integral.
      </Description>
      <ul>
        <Description>
          MUÑOZ, FRANCISCO JOSE
        </Description>
        <Description>
          AIMALE, MARIO RITO
        </Description>
        <Description>
          CARRIZO, PAULA AGOSTINA
        </Description>
      </ul>
    </ContainerInfo>

    <Hr />

    <ContainerInfo>
      <Title>Áreas de Practica</Title>
      <Description>
        La trayectoria profesional y académica nos permite brindar un amplio asesoramiento a nuestros  clientes en las diferentes áreas:
      </Description>
      <ul>
        <Description>
          - DERECHO CIVIL COMERCIAL Y AMBIENTAL
        </Description>
        <Description>
          - DERECHO DE FAMILIA
        </Description>
        <Description>
          - DERECHO PENAL
        </Description>
        <Description>
          - DERECHO LABORAL
        </Description>
        <Description>
          - DERECHO ADMINISTRATIVO
        </Description>
        <Description>
          - CONSTITUCION DE PERSONAS JURIDICAS
        </Description>
        <Description>
          - MEDIACION JUDICIAL Y EXTRAJUDICIAL
        </Description>
      </ul>
    </ContainerInfo>

    <Hr />

    <ContainerInfo>
      <Title>Contacto</Title>
      <Description>Tel: 02656-420776</Description>
      <Description>
        Mail: <a href="mailto:jf-paco@live.com">jf-paco@live.com</a>
      </Description>
      <Description>
        Mail: <a href="mailto:marioaimale@hotmail.com">marioaimale@hotmail.com</a>
      </Description>
    </ContainerInfo>

    <Hr />

    <ContainerInfo>
      <Title>Días y Horarios de Atención</Title>
      <Description>Martes</Description>
      <Description>Miércoles</Description>
      <Description>Jueves</Description>
      <Description>De 17 a 20:30 horas</Description>
    </ContainerInfo>
  </ContainerJuristic>
);

export default Juristic;
