import React from "react";
import { Routes, Route } from "react-router-dom";
import Footer from './components/Footer';
import Header from './components/Header';
import Redes from './components/Redes';
import Home from "./pages/Home";
import Architect from "./pages/Architect";
import Juristic from "./pages/Juristic";
import RealEstate from "./pages/RealEstate";
import Projects from "./pages/Projects";
import Project from "./pages/Project";
import './App.css';

const App = () => (
  <div className="App">
    <Header />
    <div className="WrapperApp">
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="arquitectura" element={<Architect />} />
          <Route path="juridico" element={<Juristic />} />
          <Route path="inmobiliaria" element={<RealEstate />} />
          <Route path="projects/:type" element={<Projects />} />
          <Route path="project/:id" element={<Project />} />
        </Route>
      </Routes>
      <Redes />
    </div>
    <Footer />
  </div>
);

export default App;
