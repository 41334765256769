import styled from "styled-components";

export const ContainerRealState = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 6%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CardContainer = styled.div`
  text-align:center;
`;

export const Card = styled.div`
  cursor: pointer;
  width: 45%;
  border: 0.1px solid #9B9B9B;
  border-radius: 10px;

  @media screen and (max-width: 768px) {
    margin-top: 10%;
    width: 60%;
  }
`;

export const Title = styled.h5`
  font-weight: bold;
  color: rgb(89,28,43);
  margin: 3%;
`;

export const ContainerLoading = styled.div`
  width: 300px;
  heght: 300px;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const Image = styled.img`
  width: 99.9%;
  heght: 250px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const Hr = styled.hr`
  width: 74%;
  margin-left: 13%;
`;
