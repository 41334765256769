import styled from "styled-components";

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
`;

export const ButtonLogo = styled.a`
  width: 100%;
  cursor: pointer;
  margin-top: 0.01%;

  @media screen and (max-width: 768px) {
    width: 50%;
  }
`;

export const Logo = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;

  @media screen and (max-width: 768px) {
    object-fit: contain;
    width: 100%;
  }
`;

export const Hr = styled.hr`
  width: 100%;
`;
