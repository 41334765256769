import { useEffect, useState } from "react";
import { State, City } from "country-state-city";
import Select from "react-select";
import './styled.css'

const SelectCity = ({ changeCity, changeState }) => {
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);

  useEffect(() => {
    setCountry({ flag: "🇦🇷", label: "Argentina", value: "AR" });
  }, []);

  useEffect(() => {
    if (state !== null)
      changeState(state.label);
    else
      changeState(state);
    setCity(null);
    changeCity(city);
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (city !== null)
      changeCity(city.label);
    else
      changeCity(city);
      // eslint-disable-next-line
  }, [city]);

  // const countries = useMemo(() => {
  //   return Country.getAllCountries().map((c) => {
  //     return {
  //       label: c.name,
  //       flag: c.flag,
  //       value: c.isoCode
  //     };
  //   });
  // }, []);

  const updatedState = (country) => {
    return State.getStatesOfCountry(country).map((s) => {
      return {
        label: s.name,
        value: s.isoCode
      };
    });
  };

  const updatedCities = (country, state) => {
    return City.getCitiesOfState(country, state).map((s) => {
      return {
        label: s.name,
        value: s.stateCode
      };
    });
  };

  return (
    <div className="s-container">
      <div className="select-container">
        {/* <Select
          id="country"
          className="basic-single-container"
          classNamePrefix="basic-single"
          name="country"
          label="country"
          options={countries}
          value={country}
          onChange={(value) => {
            console.log(value)
            setCountry(value);
          }}
          isClearable={true}
        /> */}

        <h5 className="buscar">Buscar</h5>

        <Select
          id="state"
          name="state"
          className="basic-single-container"
          label="state"
          placeholder= 'Provincia'
          options={country ? updatedState(country.value) : []}
          value={state}
          onChange={(value) => {
            setState(value);
          }}
          isClearable={true}
        />

        <Select
          id="city"
          name="city"
          className="basic-single-container"
          label="city"
          placeholder='Localidad'
          options={
            country && state ? updatedCities(country.value, state.value) : []
          }
          value={city}
          onChange={(value) => {
            setCity(value);
          }}
          isClearable={true}
        />
      </div>
    </div>
  );
};

export default SelectCity;
