import React from "react";
import Pagination from "../../components/Pagination";
import CarouselLarge from "../../components/CarouselLarge";
import { ContainerArchitect } from "./styled";

const Architect = ({ projects }) => {

  const renderCarousel = () => (
    <ContainerArchitect>
      {projects?.map((project) => {
        return (
          <CarouselLarge key={project.id} project={project} />
        );
      })}
    </ContainerArchitect>
  );

  return (
    <Pagination items={projects} renderItem={renderCarousel} />
  )
};

export default Architect;
