import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getAllProjects } from "../../actions/projects.actions";
import Carousel from "../../components/Carousel";
import Pagination from "../../components/Pagination";
import Loading from "../../components/Loading";
import Architect from "../Architect/Architect";
import SelectCity from "../../components/SelectCity/SelectCity";
import { ProjectsContainer, CarouselContainer } from "./styled";

const Projects = () => {
  const [projects, setProjects] = useState(null);
  const [filtredProjects, setFiltredProjects] = useState(null);
  const [loading, setLoading] = useState(true);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const { type } = useParams();

  const getProjects = async () => {
    const res = await getAllProjects();
    filterProjects(res["data"]);
  };

  const filterProjects = async (projects) => {
    let filter_projects = [];
    console.log(projects)
    projects?.forEach((project) => {
      if (project.type === type)
        filter_projects.push(project);
    });
    setProjects(filter_projects);
    setFiltredProjects(filter_projects);
    setLoading(false);
  };

  const convertStr = (str) => {
    if (str !== null) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    }
  };

  const compareStr = (str1, str2) => {
    return convertStr(str1) === convertStr(str2);
  };

  const filterCityStateProjects = (filter, type) => {
    let filter_projects = [];
    if (filter !== null) {
      filtredProjects?.forEach((project) => {
        if (type === "state"){
          if (compareStr(project.state, filter)) {
            filter_projects.push(project);
          }
        }
        if (type === "city"){
          if (compareStr(project.city, filter)) {
            filter_projects.push(project);
          }
        }
      });
      setFiltredProjects(filter_projects);
    } else {
      if (type === "state"){
        setFiltredProjects(projects);
      } else {
        filterCityStateProjects(state, "state");
      }
    }
  };

  useEffect(() => {
    getProjects();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    filterCityStateProjects(state, "state");
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    filterCityStateProjects(city, "city");
    // eslint-disable-next-line
  }, [city]);

  const renderCarousel = (projects) => (
    <CarouselContainer>
      {projects && projects?.map((project) => {
        return (
          <Carousel
            key={project.id}
            project={project}
          />
        );
      })}
    </CarouselContainer>
  );

  return (
    <ProjectsContainer>
      {loading ? (
        <Loading />
      ) : (
        <>
          {type === "arquitectura" ? (
            <Architect projects={filtredProjects} />
          ) : (
            <>
              {(filtredProjects && filtredProjects.length > 0) && (
                <SelectCity changeCity={setCity} changeState={setState} />
              )}
              <Pagination items={filtredProjects} renderItem={renderCarousel} />
            </>
          )}
        </>
      )}
    </ProjectsContainer>
  )
};

export default Projects;
