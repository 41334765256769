import React, { useState, useEffect } from "react";
import { getProjectById } from "../../actions/projects.actions";
import { useParams } from "react-router-dom";
import { image_url } from "../../utils/utils";
import Pagination from "../../components/Pagination";
import Loading from "../../components/Loading/Loading";
import {
  ProjectContainer,
  Title,
  ImagesContainer,
  TextContainer,
  Description,
  ImageContainer,
  Image,
  City
} from "./styled";

const Project = () => {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);

  const getProject = async () => {
    const res = await getProjectById(id);
    setProject(res["data"]);
    setLoading(false);
  };

  useEffect(() => {
    getProject();
    // eslint-disable-next-line
  }, []);

  const renderImages = (images) => (
    <ImagesContainer>
      {images && images?.map((image) => {
        return (
          <ImageContainer key={image.id}>
            <Image
              className=""
              hideZoom="false"
              hideDownload="true"
              small={image_url(image.url)}
              large={image_url(image.url)}
              alt=""
            />
          </ImageContainer>
        );
      })}
    </ImagesContainer>
  );

  return (
    <ProjectContainer>
      {loading ? (
        <Loading />
      ) : (
        <>
          <TextContainer>
            <City>{`${project.city}, ${project.state}`}</City>
            <Title>{project.title}</Title>
            <Description>{project.description}</Description>
          </TextContainer>

          <Pagination items={project.images} renderItem={renderImages} />
        </>
      )}
    </ProjectContainer>
  )
};

export default Project;
