import styled from "styled-components";

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-top: 3%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
