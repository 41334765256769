import styled from "styled-components";

export const CardHomeContainer = styled.div`
  width: 40%;
  margin-top: 6%;

  @media screen and (max-width: 768px) {
    width: 70%;
  }
`;

export const AnclaContainer = styled.div`
  color: rgb(89,28,43);
  cursor: pointer;
  width: 80%;
  heigth: 100px;
  border: 0.1px solid #9B9B9B;
  border-radius: 10px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Image = styled.img`
  width: 99.9%;
  heght: 250px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const Title = styled.h5`
  padding: 3%;
  font-weight: bold;
`;

export const Hr = styled.hr`
  width: 74%;
  margin-left: 13%;
`;