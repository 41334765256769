import axios from "axios";
import { API_URL, API_TOKEN } from "../config/variables";

export const getAllProjects = async () => {
  const apiUrl = `${API_URL}/api/v1/projects`;
  let result = "";
  await axios.get(apiUrl, { headers: { token: API_TOKEN } })
    .then((response) => {
      result = { status: response.status, data: response.data.data };
    }, (error) => {
      result = { status: error?.response?.status, data: [] };
    });
  return result;
};

export const getProjectById = async (id) => {
  const apiUrl = `${API_URL}/api/v1/project/${id}`;
  let result = "";
  await axios.get(apiUrl, { headers: { token: API_TOKEN } })
    .then((response) => {
      result = { status: response.status, data: response.data.data };
    }, (error) => {
      result = { status: error?.response?.status, data: {} };
    });
  return result;
};
