import styled from "styled-components";

export const Title = styled.h5`
  font-weight: bold;
  color: rgb(89,28,43);
  text-overflow: ellipsis;
  width: 300px;
  overflow: hidden;
  white-space: pre-line;
`;
