import React from "react";
import { useNavigate } from "react-router";
import { ContainerHeader, ButtonLogo, Logo, Hr } from "./styled";
import { useMediaQuery } from 'react-responsive'
import logoDesktop from '../../assets/images/logoDesktop.jpg';
import logoMobile from '../../assets/images/logoMobile.png';

const Header = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const onClickLogo = () => {
    navigate("/");
  };

  return (
    <ContainerHeader>
      <ButtonLogo onClick={onClickLogo}>
        <Logo src={isMobile ? logoMobile : logoDesktop} alt="Logo" />
      </ButtonLogo>
      <Hr />
    </ContainerHeader>
  )
};

export default Header;
