import styled from "styled-components";
import ModalImage from "react-modal-image-responsive";

export const ProjectContainer = styled.div`
  margin-top: 3%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 85%
`;

export const Title = styled.h3`
  font-family: 'Montserrat', serif;
  font-weight: 400;
  font-style: italic;
  color: rgb(89,28,43);
  text-align: center;
`;

export const Description = styled.p`
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  margin-top: 2%;
  padding-left: 30px;
  font-weight: 400;
`;

export const Image = styled(ModalImage)`
  width: 370px;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
`;

export const ImageContainer = styled.div`
  border: 0.1px solid #9B9B9B;
  border-radius: 10px;
  margin: 2%;
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const TextContainer = styled.div`
  margin-bottom: 1%;
`;

export const City = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  color: rgb(89,28,43);
  margin-right: 3%;
  text-align: right;
`;