import styled from "styled-components";

export const ContainerJuristic = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

export const ContainerInfo = styled.div`
  text-align: left;
  margin-left: 1%;
`;

export const Title = styled.h3`
  font-family: 'Montserrat', serif;
  font-weight: 400;
  font-style: italic;
  color: rgb(89,28,43);
  text-align: left;
`;

export const Description = styled.li`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  margin-top: 20px;
  padding-left: 30px;
  font-weight: 400;
  text-align: left;
`;

export const Hr = styled.hr`
  width: 100%;
`;
