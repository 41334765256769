import styled from "styled-components";

export const ContainerFooter = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 6%;
  background: rgb(89,28,43);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  @media screen and (max-width: 768px) {
    height: 7%;
  }
`;

export const TextFooter = styled.p`
  text-align: center;
  color: #D8D8D8;
  margin-top: 1%;

  @media screen and (max-width: 768px) {
    margin: 15%;
  }
`;
