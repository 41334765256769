import React, { useEffect } from "react";
import CardHome from "../../components/CardHome";
import { getHome } from "../../actions/home.actions";
import { HomeContainer } from "./styled";

const Home = () => {

  const pingHome = async () => {
    const res = await getHome();
    console.log(res["data"])
  };

  useEffect(() => {
    pingHome();
    // eslint-disable-next-line
  }, []);

  return (
    <HomeContainer>
      <CardHome name="JURIDICO" image="juridico"/>
      <CardHome name="INMOBILIARIO" image="inmobiliaria"/>
      <CardHome name="ARQUITECTO" image="arquitectura"/>
    </HomeContainer>
  )
};

export default Home;
