import React from "react";
import { ContainerFooter, TextFooter } from "./styled";

const Footer = () => (
  <ContainerFooter>
    <TextFooter>
      Av. San Martin 1051 | Tel. 420776 | Tilisarao - San Luis
    </TextFooter>
  </ContainerFooter>
);

export default Footer;
